import { createApp } from 'vue'
import App from './App.vue'
import "primeicons/primeicons.css"
import router from './router'
import store from './store'
import './index.css'

import VOtpInput from "vue3-otp-input";

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'


createApp(App).use(store).use(Toast).use(router).component('v-otp-input', VOtpInput).mount('#app')
