import axios from 'axios'
import RegisterService from '../../services/RegisterService'
import router from '../../router'

export default {
    state: {
        registration:{
            firstName: "",
            lastName: "",
            email: "",
            phone: null,
            password: "",
            confirm: "",
            stage : 0,
            error: false,
            errorMsg: "",
            loading: false,
            disabled: false
        },
        storeStatus: false,
        storeSetup: {
            category: "",
            token: null
        }
    },
    getters: {
        getStoreStatus(state){
            return state.storeStatus
        },
        getPhone(state){
            return state.registration.phone
        },
        getToken(state){
            if(localStorage.getItem("abamade_auth_vendor_token"))
                state.storeSetup.token = localStorage.getItem("abamade_auth_vendor_token")
                return state.storeSetup.token
        },
        getStoreType(state){
            return state.storeSetup.category
        },
        getRegisterEmail(state){
            return state.registration.email
        },
        getErrorStatus(state){
            return state.registration.error
        },
        getErrorMsg(state){
            return state.registration.errorMsg
        },
        getStage(state){
            return state.registration.stage
        },
        getLoading(state){
            return state.registration.loading
        },
        getDisabled(state){
            return state.registration.disabled
        }

    },
    mutations: {
        setStoreStatus(state, status){
            state.storeStatus = status
        },
        setStage(state, stage){
            state.registration.stage = stage
        },
        setToken(state, token){
            state.storeSetup.token = token
            localStorage.setItem("abamade_auth_vendor_token", token)
        },
        setError(state, {message, status}){
            state.registration.error = status
            state.registration.errorMsg = message
        },
        setLoading(state, status){
            state.registration.loading = status
        },
        setDisabled(state, status){
            state.registration.disabled = status
        },
        setCategory(state, category){
            state.storeSetup.category = category
        },
        setUserRegistrationData(state, userData) {
            state.registration.firstName = userData.firstName;
            state.registration.lastName = userData.lastName;
            state.registration.email = userData.email;
            state.registration.phone = userData.phoneNumber;
            state.registration.password = userData.password;
            state.registration.accountType = userData.accountType;
        }
    },
    actions: {
        async registerUser({ commit, state }, data){
            try {
                const regUser = await RegisterService.registerUser(data)
                commit("setUserRegistrationData", data)
                commit("setStage", 2)       
                commit("setLoading", false)
                commit("setDisabled", false)
                commit("setToken", regUser.token)
            } catch (error) {
                commit("setError", {status: true, message: error.response.data.errors[0].error})
                setTimeout(()=>{
                    commit("setError", {status: false, message: ""})
                    commit("setLoading", false)                
                    commit("setDisabled", false)                

                }, 3000)
            }
        },

        async verifyOtp({ commit, state}, data){
            try {
                const verifyUser = await RegisterService.verifyOtp(data)
                commit("setLoading", false)     
                commit("setDisabled", false)
                commit("setStage", 3)
                commit("fetchUser")       
            } catch (error) {
                console.log(error);
                commit("setError", {status: true, message: error.response.data.errors[0].error})
                setTimeout(()=>{
                    commit("setError", {status: false, message: ""})
                    commit("setLoading", false)                
                    commit("setDisabled", false)                

                }, 3000)
            }
        },

        async createStore({commit, state}, data){
            try {
                const storeCreate = await RegisterService.createStore(data)
                commit("setLoading", false)     
                commit("setDisabled", false)
                window.location.href = "/"
            } catch (error) {
                console.log(error);
                commit("setError", {status: true, message: error.response.data.errors[0].error})
                setTimeout(()=>{
                    commit("setError", {status: false, message: ""})
                    commit("setLoading", false)                
                    commit("setDisabled", false)                

                }, 3000)
            }
        }
    }
}