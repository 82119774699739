import axios from 'axios'

export default {
    state: {
        withdrawStep : 0,
        amount: null,
        account_name: "",
        bankName: "",
        bankCode: "",
        account_number: "",
        description: "",
        receipentCode: ""
    },
    getters: {
        getWithdrawStep(state){
            return state.withdrawStep
        },
        getWithdrawData(state) {
            return {
                amount: state.amount,
                bankName: state.bankName,
                bankCode: state.bankCode,
                account_name: state.account_name,
                account_number: state.account_number,
                description: state.description
            };
        },
        getReceipentCode(state){
            return state.receipentCode
        }

    },
    mutations: {
        setWithdrawStep(state, step){
            state.withdrawStep = step
        },

        setRecepientCode(state, code){
            state.receipentCode = code
        },

        saveWithdrawDetails(state, data){
            state.account_name = data.account_name
            state.amount = data.amount
            state.account_number = data.account_number
            state.bankName = data.bankName
            state.bankCode = data.bankCode
            state.description = data.description

        }

    },
    actions: {
        
    }

}