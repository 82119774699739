import axios  from "axios"
import store from "../store"

class RegisterService {
    static registerUser(data){
        return new Promise(async (resolve, reject)=>{
            try {
                const registerVendor = await axios.post("https://backend.abamade.com.ng/api/user/create", data)
                resolve(registerVendor.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    static verifyOtp(data){
        return new Promise(async (resolve, reject)=>{
            try {
                const verify = await axios.post("https://backend.abamade.com.ng/api/auth/verify", data)
                resolve(verify.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    static createStore(data){
        return new Promise(async (resolve, reject)=>{
            try {
                const storeCreate = await axios.post("https://backend.abamade.com.ng/api/vendor/store/create", data)
                resolve(storeCreate.data)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default RegisterService