import axios from 'axios'
import store from '../store'

const url = 'https://backend.abamade.com.ng/api'

class ChatService {
    static createConversation({ senderId, receiverId }) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${url}/conversations`, {
                    senderId,
                    receiverId
                }, {
                    headers: {
                        'Authorization': `Bearer ${store.state.token}`
                    }
                })
                const data = res.data.data;
                resolve(data)
            }
            catch(error) {
                reject(error)
            }
        })
    }

    static getAllConversations() {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${url}/conversations/store`, {
                    headers: {
                        'Authorization': `Bearer ${store.state.token}`
                    }
                })
                const data = res.data.data;
                resolve(data)
            }
            catch(error) {
                reject(error)
            }
        })
    }

    static getAllMessages(conversationId) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${url}/messages/${conversationId}`, {
                    headers: {
                        'Authorization': `Bearer ${store.state.token}`
                    }
                })
                const data = res.data.data;
                resolve(data)
            }
            catch(error) {
                reject(error)
            }
        })
    }

    static sendMessage({ conversationId, sender, text }) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(`${url}/messages`,{
                    conversationId,
                    sender,
                    text
                }, {
                    headers: {
                        'Authorization': `Bearer ${store.state.token}`
                    }
                })
                const data = res.data.data;
                resolve(data)
            }
            catch(error) {
                reject(error)
            }
        })
    }
}

export default ChatService;