<template>

  <router-view/>
  
  <div class="flex">
    <div class="w-64 shadow-md absolute bottom-3 left-3 bg-green-500 text-white p-3 rounded-sm flex items-center justify-between mb-4 fade-in"  v-if="$store.state.loginMessage">
      <div>
        {{ $store.state.loginMessage }}
      </div>
      <div @click="$store.state.loginMessage =  ''">
        <i class="pi pi-times text-gray-300 hover:text-white cursor-pointer"></i>
      </div>
    </div>
  </div>
</template>



<script>
import { mapGetters} from 'vuex'
import AccountServices from './services/AccountServices'
export default {

  computed: {
    ...mapGetters(['getStoreStatus'])
  },
  mounted(){

    if(localStorage.getItem("abamade_auth_vendor_token")){
      this.$store.dispatch('fetchUser')
    }
  },
  created() {
    this.getStoreInfo()

  },
  methods: {
    async getStoreInfo(){
      const token = localStorage.getItem("abamade_auth_vendor_token");

		try {
			const store = await AccountServices.getStoreInfo(token)
			this.$store.commit("setStoreStatus", true)
      this.$store.commit("setAccountType", store.businessType)
		} catch (error) {
			this.$store.commit("setStoreStatus", false)
		}
	},

  }
}
</script>