import axios from 'axios'
import WalletService from '../../services/WalletServices'

export default {
    state: {
        isPinSet: false,
        availableBalance: 0,
        mainBalance: 0,
        accountName: "",
        accountNumber: "",
        bankName: "",
        bankCode: ""

    },
    getters: {
        getPinStatus(state){
            return state.isPinSet
        },
        getWalletDetails(state){
            return {
                availableBalance: state.availableBalance,
                mainBalance: state.mainBalance,
                accountName: state.accountName,
                accountNumber : state.accountNumber,
                bankName: state.bankName,
                bankCode: state.bankCode,
            }
        }
    },
    mutations: {
        setPinStatus(state, status){
            state.isPinSet = status
        },
        SET_AVAILABLE_BALANCE(state, balance) {
            state.availableBalance = balance;
        },
        SET_MAIN_BALANCE(state, balance) {
            state.mainBalance = balance;
        },
        SET_ACCOUNT_NAME(state, accountName) {
            state.accountName = accountName;
        },
        SET_ACCOUNT_NUMBER(state, accountNumber) {
            state.accountNumber = accountNumber;
        },
        SET_BANK_NAME(state, bankName) {
            state.bankName = bankName;
        },
        SET_BANK_CODE(state, bankCode) {
            state.bankCode = bankCode;
        },
    },
    actions: {
        async getWalletInfo({commit}){
            try {
                const wallet = await WalletService.getWallet()
                commit('SET_AVAILABLE_BALANCE', wallet.wallet.availableBalance);
                commit('SET_MAIN_BALANCE', wallet.wallet.mainBalance);
                commit('SET_ACCOUNT_NAME', wallet.getRequireMents.accountName);
                commit('SET_ACCOUNT_NUMBER', wallet.getRequireMents.recipientAccountNumber);
                commit('SET_BANK_NAME', wallet.getRequireMents.selectedBank);
                commit('SET_BANK_CODE', wallet.getRequireMents.bankCode);
            } catch (error) {
                console.log(error);
            }
        }
    }

}