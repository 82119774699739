import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'View',
    component: () => import('../views/app/View.vue'),
    beforeEnter(to, from, next){
      if(localStorage.getItem('abamade_auth_vendor_token')){
        next()

      }else{
        next("/login")

      } 
    },
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import('../views/app/dashboard/Dashboard.vue')
      },
      {
        path: "/orders",
        name: "Orders",
        component: () => import('../views/app/orders/Order.vue'),
        children: [
          {
            path: ":id",
            name: "OrderDetails",
            component: () => import('../views/app/orders/components/OrderDetail.vue'),
          }
        ]
      },
      {
        path: "/product",
        name: "Product",
        component: () => import('../views/app/product/Product.vue'),
        beforeEnter(to, from, next){
          if(store.getters.getkycStatus){
            next()
          }else{
            
            next('/account')
          }
        }
      },
      {
        path: "/product/add",
        name: "AddProduct",
        component: () => import('../views/app/product/AddProduct.vue'),
        beforeEnter(to, from, next){
          if(store.getters.getkycStatus){
            next()
          }else{
            next('/account')
          }
        }
      },
      {
        path: "/product/edit",
        name: "EditProduct",
        component: () => import('../views/app/product/edit-product/EditProduct.vue'),
        beforeEnter(to, from, next){
          if(store.getters.getkycStatus){
            next()
          }else{
            next('/account')
          }
        }
      },
      {
        path: "/chat",
        name: "Chat",
        component: () => import('../views/app/chat/Chat.vue'),
        children: [
          {
            path: "",
            name: "EmptyConversation",
            component: () => import('../views/app/chat/components/EmptyConvo.vue'),
          },
          {
            path: ":id",
            name: "Conversation",
            component: () => import('../views/app/chat/components/ChatBody.vue'),
          },

        ]
      },
      {
        path:"/mobilechat",
        name:'MobileChat',
        component: () =>  import('../views/app/chat/MobileChat.vue'),
        children : [
          {
            path: ":id",
            name: "Conversation",
            component: () => import('../views/app/chat/components/ChatBody.vue'),
          }
        ]
      },
      {
        path: "/account",
        name: "Account",
        component: () => import('../views/app/account/AccountSetting.vue')
      },
      {
        path:'/transaction',
        name:'Transaction',
        component: () => import('../views/app/transaction/Transaction.vue')
      },
      {
        path:'/quotation',
        name:'Quotation',
        component:() => import('../views/app/quotations/Quotation.vue'),
      },
      {
        path:'/quotation/:id',
        name:'QuotationDetail',
        component: () => import('../views/app/quotations/components/QuotationDetail.vue')
      }
    ]
  },
  {
    path:'/',
    name:'Authentication',
    component: ()=> import('../views/auth/View.vue'),
    children:[
      {
        path:"/signup",
        name: "SignUp",
        component:()=> import('../views/auth/signup/SignUp.vue')
      },
      {
        path:"/login",
        name: "SignIn",
        component:()=> import('../views/auth/signin/Login.vue')
      },
      {
        path:"/forgot",
        name: "Forgot",
        component:()=> import('../views/auth/forgot/Forgot.vue')
      }
    ]
  },
  
  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0,0);
  }
})

export default router
