import router from "../../router";
import ChatService from "../../services/ChatService";
import store from "../index"

export default {
    state: {
        conversations: [],
        allConversations: [],
        messages: [],
        currentChatUser: {}
    },
    getters: {
        conversations(state){
            return state.conversations
        },
        unreadConversations(state) {
            
        },
        readConversations(state) {
            let total = 0;
            state.cart.forEach(item => {
                total += item.qty
            })
            return total
        },
        messages(state) {
            return state.messages;
        },
        currentChatUser(state) {
            return state.currentChatUser;
        }
    },
    mutations: {
        populateConversations(state, conversations) {
            state.conversations = conversations;
            state.allConversations = conversations
        },
        populateMessages(state, messages) {
            state.messages = messages;
        },
        setCurrentChatUser(state, user) {
            state.currentChatUser = user;
        },
        addChatToConversations(state, { conversationId, senderId, text }) {
            const conversation = state.conversations.find(conversation => conversation._id === conversationId);
            const createdAt = Date.now();
            conversation.chats.push({ senderId, text, createdAt });
        },
        addChatToMessages(state, { conversationId, senderId, text }) {
            state.messages.push({ conversationId, senderId, text })
        }
    },
    actions: {
        async fetchConversations({commit, state}, id) {
            const conversations = await ChatService.getAllConversations()
            commit('populateConversations', conversations)
        },  
        async createConversation({commit, state}, { senderId, receiverId }) {
            const conversation = await ChatService.createConversation({senderId, receiverId})
            router.push(`/chat/${conversation._id}`)
        },
        async fetchMessages({ commit, state }, id) {
            try {
                const messages = await ChatService.getAllMessages(id);
                commit('populateMessages', messages);
            }
            catch(error) {
                console.log(error)
            }
        },
        clearMessages({ commit, state }){
            state.messages = []
        }
    }
}